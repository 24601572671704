export const authModalTypes = {
  signIn: 'signIn',
  signUp: 'signUp',
  specialSignUp: 'specialSignUp',
  code: 'code',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  verification: 'verification',
  verificationEmail: 'verificationEmail',
  message: 'message',
  changePhone: 'changePhone',
  sanamiaMig: 'sanamiaMig',
  close: null,
};

export const EMBED_STEPS = {
  SIGN_UP_SIGN_IN: 0,
  PASSWORD: 1,
  FORGOT_PASSWORD: 2,
  VERIFICATION: 3,
  CONFIRMATION: 4,
  SUCCESS: 5,
};

export default authModalTypes;
